.t2a-row {
  padding: 4rem 0 10rem 0;
}

.t2a-input {
  display: flex;
  flex-direction: column;
  width: 40.75rem;
  //width: 36vw;
  height: 50.875rem;
  padding: 0.99138rem;
  align-items: center;
  gap: 3.6875rem;
  flex-shrink: 0;
  border-radius: 3.9375rem;
  //border: 1px solid #FFF;
  background: linear-gradient(118deg, rgba(255, 255, 255, 0.50) -19.85%, rgba(235, 235, 235, 0.37) 4.2%, rgba(224, 224, 224, 0.29) 13.88%, rgba(212, 212, 212, 0.21) 27.98%, rgba(207, 207, 207, 0.18) 37.8%, rgba(202, 202, 202, 0.14) 44.38%, rgba(200, 200, 200, 0.13) 50.54%, rgba(196, 196, 196, 0.10) 60.21%);
  box-shadow: 0px 1.98271px 47.58506px -1.98271px rgba(0, 0, 0, 0.18);
}

.ratio-choice {
  color: white;
}

.style-input {
  margin: 0 0 0 0;
  display: flex;
  width: 36.125rem;
  padding: 0.69094rem 1.063rem;
  align-items: stretch;
  border-radius: 2.65756rem;
  border: 1.276px solid rgba(0, 0, 0, 0.00);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3.40167px 3.40167px 0px rgba(61, 61, 61, 0.35) inset;
  backdrop-filter: blur(42.52089309692383px);
  color: white;
  cursor: pointer;
}

.prompt-input {
  margin: 5% 0 0 0;
  display: flex;
  width: 36.125rem;
  height: 8.625rem;
  padding: 0.69094rem 1.063rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5315rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  border: 1.276px solid rgba(0, 0, 0, 0.00);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3.40167px 3.40167px 0px rgba(61, 61, 61, 0.35) inset;
  backdrop-filter: blur(42.52089309692383px);
  color: white;
}

.t2a-btn {
  font-family: ManifoldCF-DemiBold, serif;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: 21.8125rem;
  height: 3rem;
  padding: 1.27738rem 2rem 1.27738rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 1.625rem;
  //border: 1.701px solid #FFF;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}

.style-div {
  .ant-input::placeholder {
    color: white; /* Replace with your desired color */
  }

  .ant-modal .ant-modal-content {
    background: linear-gradient(118deg, rgba(255, 255, 255, 0.50) -19.85%, rgba(235, 235, 235, 0.37) 4.2%, rgba(224, 224, 224, 0.29) 13.88%, rgba(212, 212, 212, 0.21) 27.98%, rgba(207, 207, 207, 0.18) 37.8%, rgba(202, 202, 202, 0.14) 44.38%, rgba(200, 200, 200, 0.13) 50.54%, rgba(196, 196, 196, 0.10) 60.21%);
  }


  .ant-modal-content {
    margin: 10rem 0 0 12rem;
    display: flex;
    width: 42.25rem;
    //width: 38rem;
    height: 32.875rem;
    padding: 0.99138rem;
    align-items: center;
    justify-content: center;
    //gap: 3.6875rem;
    //flex-shrink: 0;
    border-radius: 0.9375rem;
    border: 1.983px solid #FFF;
    background: linear-gradient(118deg, rgba(255, 255, 255, 0.50) -19.85%, rgba(235, 235, 235, 0.37) 4.2%, rgba(224, 224, 224, 0.29) 13.88%, rgba(212, 212, 212, 0.21) 27.98%, rgba(207, 207, 207, 0.18) 37.8%, rgba(202, 202, 202, 0.14) 44.38%, rgba(200, 200, 200, 0.13) 50.54%, rgba(196, 196, 196, 0.10) 60.21%);
    box-shadow: 0px 1.98271px 47.58506px -1.98271px rgba(0, 0, 0, 0.18);
    overflow-y: auto;

    .ant-modal-close-x {
      display: none;
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    display: none;
  }
}