.login-bg{
  color: black;
  background-color: black;
  height: 150vh;
}
.login-col{
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.ant-input{
  border: none!important;
  :hover{
    border: none!important;
  }
}
.login-btn{
  display: flex;
  width: 16.375rem;
  height: 3.5rem;
  padding: 1.27738rem 4rem 1.27738rem 4rem;
  //flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: large;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 1.625rem;
  background: rgba(47, 47, 47, 0.24);
  box-shadow: 2.5512535572052px 2.5512535572052px 1.7008357048034668px 0px rgba(255, 255, 255, 0.10) inset, -1.7008357048034668px 0px 1.7008357048034668px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
  color: white;
}
