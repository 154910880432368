.acc-txt{
  color: white;
  font-family: 'ManifoldCF-Medium', sans-serif;
}

.acc-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-input{
  width: 37.45288rem;
  height: 4.64525rem;
  flex-shrink: 0;
  border-radius: 3.3125rem;
  //opacity: 0.65;
  background: rgba(82, 82, 82, 0.69);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.85) inset;
  color: white;
  font-size: 1.25rem;
}
.ant-input{
  border: none!important;
}

.up-avatar-btn{
  display: flex;
  width: 21.125rem;
  height: 4.08325rem;
  padding: 0.66138rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.66138rem;
  flex-shrink: 0;
  border-radius: 3.96825rem;
  border: 1px solid #D6D6D6;
  background: rgba(143, 55, 144, 0.00);
  color: white;
}

.save-profile-btn{
  display: flex;
  width: 21.125rem;
  height: 4.08325rem;
  padding: 0.66138rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.66138rem;
  flex-shrink: 0;
  border-radius: 3.96825rem;
  background: #8F3790;
  color: white;
  margin: 1rem 0 1rem 0;
}
.crop-md{
  .ant-modal-content{

  }
  .ant-modal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .ant-btn-primary{
    display: flex;
    width: 21.125rem;
    height: 2.5rem;
    padding: 0.66138rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.66138rem;
    flex-shrink: 0;
    border-radius: 3.96825rem;
    background: #8F3790;
  }
  .ant-btn-default{
    margin-top: 1rem;
    display: flex;
    width: 21.125rem;
    height: 2.5rem;
    padding: 0.66138rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.66138rem;
    flex-shrink: 0;
    border-radius: 3.96825rem;
  }
}