.split-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.split-image {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: clip-path 0ms;
  //object-fit: contain!important;
}

.split-image img {
  width: 100%;
  height: 100%;
  user-select: none;
  object-fit: contain; /* Add this line to maintain the original aspect ratio */
}




.sp-divider {
  position: absolute;
  width: 80px;
  height: 90%;
  background: none;
  cursor: col-resize;
  left: calc(50% - 2px);
  margin-left: -36px;
  top: 5%;
  z-index: 10;
  display: flex;
  align-items: center;
}

.split-image:first-child {
  z-index: 2;
  clip-path: inset(0% 50% 0% 0%);
}

.split-image:last-child {
  z-index: 2;
  clip-path: inset(0% 0% 0% 50%);
}
