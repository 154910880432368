.preview-div {
  .eye-preview-div {
    width: 100%;
    height: 100%;
  }

  .ant-modal-content {
    width: 150%;
    height: 100%;
    position: absolute;
    top: 1500%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .eye-preview-title {
    color: #FFF;
    text-align: center;
    font-family: ManifoldCF-DemiBold, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: auto;
    margin-bottom: 1rem;
  }

  .eye-toolbar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //bottom: 32px;
    //left: 50%;
    //padding: 15px 24px;
    color: #fff;
    font-size: 30px;
    //background-color: rgba(0, 0, 0, 0.1);
    //border-radius: 100px;
  }
}