.create-btn{
  border-radius: 1.625rem;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.5512535572052px 2.5512535572052px 1.7008357048034668px 0px rgba(255, 255, 255, 0.10) inset, -1.7008357048034668px 0px 1.7008357048034668px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
  display: flex;
  width: 20%;
  height: 3rem;
  padding: 1.27738rem 1rem 1.27738rem 1rem;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  color: white;
  margin: 2rem auto;
  cursor: pointer;
}