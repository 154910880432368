.t2a-gen-img {
  margin: auto;
  width: 85% !important;
}
.ant-image {
  display: block;
}
.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 15px 24px;
  color: #fff;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}