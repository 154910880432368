.imagination-font {
  color: #FFF;
  text-align: center;
  font-family: ManifoldCF-Medium, sans-serif;
  //font-size: 5.5625rem;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 89.3%; /* 4.96731rem */
}

.unleashed-font {
  color: #FFF;
  font-family: ManifoldCF-Medium, sans-serif;
  //font-size: 10rem;
  font-size: 8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 89.3%;
}

.over-section {
  position: absolute;
  top: 15vw;
  left: 16vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.small-btn {
  text-decoration: underline;
  cursor: pointer;
  color: white;
  text-align: center;
  font-family: ManifoldCF-Medium, serif;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.start-btn {
  color: white;
  width: 50%;
  padding: 20px;
  justify-content: center;
  display: flex;
  height: 3.2rem;
  flex-direction: column;
  align-items: center;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 1.625rem;
  //border: 0.101px solid #FFF;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
  text-align: center;
  font-family: ManifoldCF-Medium, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 3% 0 3% 0;
}

.ant-btn-default{
  border: none;
  :hover{
    //color: white!important;
  }
}