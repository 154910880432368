.signup-btn {
  border-radius: 1.5625rem;
  background: #8F3790;
  width: 13.12713rem;
  height: 2rem;
  flex-shrink: 0;
  color: white;
}

.signup-panel {
  display: flex;
  width: 24.3125rem;
  height: 30rem;
  padding: 1.27738rem 0.75481rem;
  margin: auto;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 2rem;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.5512535572052px 2.5512535572052px 1.7008357048034668px 0px rgba(255, 255, 255, 0.10) inset, -1.7008357048034668px 0px 1.7008357048034668px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}

.signup-form {
  width: 21.74713rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  //opacity: 0.54;
  background: #9C9C9C;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.password-signup-form {
  border: none;

  .ant-input {
    background: none;
    color: white;
  }
}

.ant-input::placeholder {
  color: white;
}

.popup-font {
  font-family: ManifoldCF-DemiBold, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.signup-confirmation-modal {
  .ant-modal-content {
    display: flex;
    width: 38.375rem;
    height: 15.4375rem;
    padding: 1.27738rem 0.75481rem;
    margin: 30% 15% 0 -10%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //gap: 1.91606rem;
    flex-shrink: 0;
    border-radius: 2rem;
    //border: 1.701px solid #FFF;
    //opacity: 0.9;
    background: rgba(255, 255, 255, 0.10);
    //background-color: #b99f9f;
    box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
    backdrop-filter: blur(52.024810791015625px);
  }

  .ant-modal-footer {
    display: initial;
  }

  .ant-modal-header {
    display: none;
  }
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: white;
}