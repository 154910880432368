.m-img {
  max-width: 100vw;
  min-height: 100vh;
  object-fit: cover;
  margin-top: 1.6rem;
}

.m-btn {
  position: absolute;
  top: 96%;
  left: 12vw;
  color: white;
  display: flex;
  width: 76vw;
  height: 2.3125rem;
  padding: 1.27738rem 4rem 1.27738rem 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.91606rem;
  //flex-shrink: 0;
  border-radius: 1.625rem;
  //border: 0.701px solid #FFF;
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}

.m-btn-txt {
  width: 14.875rem;
  height: 1.5625rem;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: ManifoldCF-Bold, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}