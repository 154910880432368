.reminder-div {
  .ant-modal-content {
    display: flex;
    width: 38.375rem;
    height: 15.4375rem;
    padding: 1.27738rem 0.75481rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 20% 0 0 -10%;
    border-radius: 2rem;
    //border: 1.701px solid #FFF;
    //opacity: 0.9;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
    backdrop-filter: blur(52.024810791015625px);
  }

  .ant-modal-footer {
    display: initial;
  }

  .ant-modal-header {
    display: none;
  }
}

.reminder-txt {
  width: 33.8075rem;
  height: 5.52931rem;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: ManifoldCF-DemiBold, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

