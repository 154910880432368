.land-txt {
  color: #FFF;
  text-align: center;
  font-family: ManifoldCF-Light, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  //width: 31.57094rem;
  width: 26vw;
  height: 9.18019rem;
  flex-shrink: 0;
  margin-top: 100px;
}

.ant-spin-text {
  margin: 20px 0 0 -50px;
  color: white;
  width: 13.05731rem;
  height: 1.75rem;
  flex-shrink: 0;
  text-align: center;
  font-family: ManifoldCF-Light, sans-serif;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.eye-preview {
  color: white;
  display: flex;
  font-size: 2rem;
  margin: 1vh 0 0 0;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  bottom: 30%
}


.up-img {
  display: flex;
  width: 36.125rem;
  height: 27.75rem;
  //padding: 0.69094rem 1.063rem;
  flex-direction: column;
  align-items: flex-start;
  //gap: 0.5315rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  //border: 1.276px solid rgba(0, 0, 0, 0.00);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3.40167px 3.40167px 0px rgba(61, 61, 61, 0.35) inset;
  backdrop-filter: blur(42.52089309692383px);
  margin-top: 15px;
  object-fit: cover;
}

.i2i-uploader {
  display: flex;
  width: 36.125rem;
  height: 27.75rem;
  padding: 0.69094rem 1.063rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5315rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  border: 1.276px solid rgba(0, 0, 0, 0.00);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3.40167px 3.40167px 0px rgba(61, 61, 61, 0.35) inset;
  backdrop-filter: blur(42.52089309692383px);
  margin-top: 15px;

  .ant-upload-drag {
    border: none;
  }
}