.qa-section{
  .ant-collapse{
    border: none;
    border-radius: 0.5rem;
    width: 50%;
  }
  .ant-collapse-item{
    border-radius: 0.5rem !important;
    background-color: white;
    margin: 2rem auto;
  }
  .ant-collapse-header-text{
    font-family: Manifold CF, sans-serif;
    font-size: large;
  }
}

@media only screen and (max-width: 900px){
  .m-qa-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8rem 0 16rem 0;
  }
  .qa-section{

    .ant-collapse{
      border: none;
      border-radius: 0.1875rem;
      width: 80%!important;
      //height: 30rem;
      padding: 0!important;
    }
    .ant-collapse-item{
      border-radius: 0.5rem !important;
      background-color: white;
      margin: 0 auto;
    }
    .ant-collapse-header-text{
      font-family: ManifoldCF-Medium, sans-serif !important;
      font-size: 0.8125rem!important;
    }
  }


  .m-qa-title{
    color: #FFF;
    text-align: center;
    font-family: ManifoldCF-Bold,sans-serif;
    font-size: 1rem!important;
    font-style: normal;
    font-weight: 700;
    line-height: 113%; /* 1.13rem */
  }
  .qa-section{
    .ant-collapse-arrow{
      font-size: 9px!important;
    }
    .ant-collapse{
      border: none;
      border-radius: 0.5rem;
      width: 100vw;
    }
    .ant-collapse-item{
      border-radius: 0.5rem !important;
      background-color: white;
      margin: 2rem auto;
    }
    .ant-collapse-header-text{
      font-family: Manifold CF, sans-serif;
      font-size: xx-small;
    }
  }
}