.create-col {
  border-radius: 32px;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.5512535572052px 2.5512535572052px 1.7008357048034668px 0px rgba(255, 255, 255, 0.10) inset, -1.7008357048034668px 0px 1.7008357048034668px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 6rem;
  cursor: pointer;
}

.create-title {
  color: white;
  font-size: 1.5rem;
  font-family: ManifoldCF-Medium, sans-serif;
  font-weight: 600;
}
.create-desc{
  font-family: 'ManifoldCF-Medium', sans-serif;
  color: white;
  font-weight: 600;
  margin-top: -20px;
}