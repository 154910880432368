.lg-email{
  width: 21.38175rem;
  height: 2.125rem;
  flex-shrink: 0;
  margin: auto;
  border-radius: 1.5625rem;
  //opacity: 0.54;
  background: #9C9C9C;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
  color: white;
  .ant-input::placeholder{
    color: white;
  }
}
.lg-email-ct-btn{
  color: white;
  display: flex;
  width: 20.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  //margin-left: -1.25rem;
  padding: 1.27738rem 2rem 1.27738rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 1.625rem;
  //border: 1.701px solid #FFF;
  background: rgba(47, 47, 47, 0.24);
  box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}
.ant-btn-default:hover {
}
.lg-email-div{
  display: flex;
  width: 31.5rem;
  height: 16rem;
  padding: 1.27738rem 0.75481rem;
  flex-direction: column;
  align-items: center;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 2rem;
  //border: 1.701px solid #FFF;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}
