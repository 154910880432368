.sim-line {
    border-right: 1px solid #000000;
}

.m-only {
    display: none;
}
.contact-btn{
    display: flex;
    width: 10.625rem;
    height: 3rem;
    padding: 1.27738rem 4rem 1.27738rem 0.75481rem;
    flex-direction: column;
    align-items: center;
    gap: 1.91606rem;
    flex-shrink: 0;border-radius: 1.625rem;
    border: 1.701px solid #FFF;
    background: #000;
    box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
    backdrop-filter: blur(52.024810791015625px);
}
.contact-font{
    flex-shrink: 0;
    color: #000;
    font-family: ManifoldCF-Medium, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    max-width: 80%;
    margin: 0;
}

.google-map > div > div {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.m-ct-contact-call{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*margin-left: 6%;*/
}

@media only screen and (max-width: 600px) {
    .contact-font{
        flex-shrink: 0;
        color: #000;
        font-family: ManifoldCF-Medium, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-width: 100%;
        width: 80%;
        text-align: center;
    }
    .m-ct-contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 4rem;
        /*margin-left: 1.6rem;*/
    }
    .m-ct-contact-call{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .m-map-row {
        margin: 2rem 0 !important;
    }

    .sim-line {
        border-right: none;
    }

    .m-only {
        display: initial;
    }

    .m-p {
        max-width: 100% !important;
    }

    .m-top {
        margin-top: 4rem !important;
    }

    .m-font {
        font-size: 38px !important;
    }
}
.pay-button {
    padding: 6px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    border: 2px solid black;
    border-radius: 12px;
    font-family: 'Proxima Nova', sans-serif;
    box-shadow: inset 0 0 0 0 #2f1717;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}

.pay-button > .in-btn-link{
    color: white;
}

.pay-button:hover {
    box-shadow: inset 1080px 0 0 0 #ffffff;
    color: black;
}
.pay-button:hover .in-btn-link{
    color: black;
}
