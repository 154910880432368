.m-icon-group-titles{
  width: 8.8125rem;
  height: 2.9375rem;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: ManifoldCF-ExtraBold,sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

.m-title-what{
  color: #FFF;
  text-align: center;
  font-family: ManifoldCF-ExtraBold,sans-serif;
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}