.home-txt {
  color: #000;
  text-align: center;
  font-family: ManifoldCF-Medium, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 65%;
  margin: auto;
  padding: 0 0 3rem 0;
}
.dt-nothing{
  display: none;
}

.m-sm-title {
  text-align: center;
  font-weight: bold;
  font-size: 2.25rem;
  font-family: 'ManifoldCF-Medium', sans-serif;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .m-mg-display{
    margin-left: 10vw!important;
  }
  .m-sm-title {
    color: #000;
    font-family: ManifoldCF-Bold, sans-serif;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 16.375rem;
    height: 3.4375rem;
    flex-shrink: 0;
    text-align: left;
    margin: 0 0 0 1.2rem;
  }
  .dt-nothing{
    display: initial;
  }

  .home-txt{
    width: 21.75rem;
    height: 20.9375rem;
    flex-shrink: 0;
    color: #000;
    font-family: ManifoldCF-Medium, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 143%; /* 1.43rem */
    text-align: left;
  }
  .m-margin{
    margin: 0 0 -12rem 0;
  }
}