.social-section {
  background-image: url("../../../../img/home/Community area new image.webp");
  margin: -50px 0 0 0;
  //width: 1920px;
}

.social-icon{
  color: white;
  font-size: 2.6rem;
  cursor: pointer;
}
.home-title{
  font-family: 'ManifoldCF-Medium', sans-serif;
}
.m-title-social{
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 2.25rem;
  color: white;
  padding: 2rem 0 0 0;
}
@media only screen and (max-width: 600px){
  .social-section {
    background-image: url("../../../../img/home/Footer stream.webp");
    margin: -50px 0 0 0;
  }
  .m-title-social{
    width: 13.875rem;
    height: 1.6875rem;
    flex-shrink: 0;
    color: #FFF;
    font-family: ManifoldCF-Bold,sans-serif;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .m-links-social{
    padding: 0 0 1rem 0!important;
  }
  .m-sc-sp{
    height: 14vh!important;
  }
}