.global-font{
  font-family: 'ManifoldCF-Medium', sans-serif;
}
.bg-row {
  background-image: url("../../img/app/Nebula Background Blur.webp");
}

.signup-remind-popup{
  font-family: 'ManifoldCF-Medium', sans-serif;
}

.head-controller {
  border-radius: 80px;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.50) 0%, rgba(235, 235, 235, 0.37) 20.07%, rgba(224, 224, 224, 0.29) 28.14%, rgba(212, 212, 212, 0.21) 39.91%, rgba(207, 207, 207, 0.18) 48.10%, rgba(202, 202, 202, 0.14) 53.59%, rgba(200, 200, 200, 0.13) 58.73%, rgba(196, 196, 196, 0.10) 66.80%);
  box-shadow: 0px 1.982710838317871px 47.585060119628906px -1.982710838317871px rgba(0, 0, 0, 0.18);
  padding: 0.5rem;
  margin: 0rem 16rem;
  .ant-segmented-item-label{
    font-family: 'ManifoldCF-Regular', sans-serif!important;
    color: #FFF;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    text-transform: capitalize;
  }

  .ant-segmented {
    font-family: 'ManifoldCF-Medium', sans-serif;
    //opacity: 0.65;
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 40.37931rem;
    width: 32vw;
    height: 3.99181rem;
    flex-shrink: 0;
    border-radius: 3.3125rem;
    //opacity: 0.65;
    background: rgba(82, 82, 82, 0.69);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.85) inset;
    margin-left: -25%;
    padding: 15px;
  }

  .ant-segmented-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ManifoldCF-Regular', sans-serif;
    min-width: initial;
    width: 4.39581rem;
    height: 2.59588rem;
    flex-shrink: 0;
    border-radius: 30px;
  }
  .ant-segmented-thumb {
    background: #C8C8C8;
    width: 4.39581rem;
    height: 2.59588rem;
    flex-shrink: 0;
    border-radius: 30px;
    //opacity: 0.21;
  }

  .ant-segmented-item-selected {
    border-radius: 30px;
    //opacity: 0.51;
    background: #C8C8C8;
    width: 4.39581rem;
    height: 2.59588rem;
    display: flex;
    align-items: center;
  }

  .ant-segmented-item-label {
    font-weight: bold;
    font-family: ManifoldCF-Regular, sans-serif;
    color: #FFF;
    text-align: center;
  }

}
@media only screen and (max-width: 600px){
  .bg-row{
    background-image: url("../../img/app/Clouds background.webp");
  }
}