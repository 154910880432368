.carousel-art {
  margin: auto;
  width: 20vw;
}

.m-title {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}

@media only screen and (max-width: 600px) {
  .carousel-art {
    width: 6rem;
  }
  .m-title {
    color: #000;
    font-family: ManifoldCF-ExtraBold, sans-serif;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    width: 12.5rem;
    margin: 1rem 0 -3rem 0;
  }
  .slick-list {
    margin-top: 4rem !important;
    margin-bottom: 2rem !important;
  }
}