.download-app-button {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.4rem;
  margin: 0 auto 1rem auto;
  border-radius: 30px;
  font-weight: bold;
}

.download-app-button:hover {
  background-color: white;
  color: black;
}
.laptop-img{
  width: 100vw;
  margin: -40vh 0 0 0;
}

.phone-img{
  width: 80%;
}
@media (max-width: 1396px) {
  .phone-image {
    width: 70%; /* Adjust as needed for smaller screens */
    height: 70%;
  }
}






