.limit-signup-btn{
  width: 12.88906rem;
  height: 2.25375rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  background: #8F3790;
  display: flex;
  align-items: center;
  justify-content: center;
}
.limit-div{
  .ant-modal-content {
    width: 38.7345rem;
    height: 20.28906rem;
    flex-shrink: 0;
    border-radius: 2.75rem;
    background-color: rgba(194, 194, 194, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-footer {
    display: initial;
  }

  .ant-modal-header {
    display: none;
  }
}