@media only screen and (max-width: 600px){
  .m-txt-footer{
    width: 25.875rem;
    height: 2.0625rem;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: ManifoldCF-Medium, sans-serif;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: auto;
  }


  .m-footer{
    display: flex!important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 2rem 0;
  }
}