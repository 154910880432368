.logo-txt {
  color: white;
  text-decoration: none;
  font-size: 7vw;
  font-family: ManifoldCF-Bold, sans-serif;
  margin: 1.5rem 0 0 4.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  h1 {
    //background-color: black;
    position: fixed;
    top: -3.9vh;
    left: 30vw;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    > a {
      text-transform: none;
      //font-weight: 300;
      //font-size: 10px;
    }
  }


  input + label {
    position: fixed;
    top: 1.5rem;
    left: 1rem;
    height: 20px;
    width: 15px;
    z-index: 5;

    span {
      position: absolute;
      width: 150%;
      height: 3px;
      top: 50%;
      //margin-top: -1px;
      left: 0;
      display: block;
      background: white;
      transition: .5s;
    }

    span:first-child {
      top: 4px;
    }

    span:last-child {
      top: 16px;
    }
  }

  label:hover {
    cursor: pointer;
  }

  input:checked + label {
    span {
      opacity: 0;
      top: 50%;
    }

    span:first-child {
      opacity: 1;
      transform: rotate(405deg);
    }

    span:last-child {
      opacity: 1;
      transform: rotate(-405deg);
    }
  }

  input ~ nav {
    background: black !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4rem;
    z-index: 3;
    transition: .5s;
    transition-delay: .5s;
    overflow: hidden;

    > ul {
      position: absolute;
      list-style-type: none;
      top: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 85%;

      > li {
        opacity: 0;
        transition: .5s;
        transition-delay: 0s;
        width: 100%;

        > a {
          text-decoration: none;
          display: block;
          padding: 20px;
          color: #FFF;
          font-family: ManifoldCF-Regular,sans-serif;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 281.5%; /* 3.51875rem */
        }

        //> .m-menu-divider {
        //  width: 100%;
        //  margin: -0.5rem 0;
        //  //color: white!important;
        //}
        .ant-divider {
          width: 50%!important;
          margin: -0.5rem 0;
          background-color: white; /* Set the background color for the divider */
        }
        .ant-divider-horizontal {
          min-width: 80vw;
          background-color: white; /* Set the background color for the divider */
        }
      }
    }
  }

  input:checked ~ nav {
    height: 100%;
    transition-delay: 0s;

    > ul {
      > li {
        opacity: 1;
        transition-delay: .5s;
      }
    }
  }
}