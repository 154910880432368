@font-face {
  font-family: 'ManifoldCF-Medium';
  src: url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Medium.woff2') format('woff2'),
  url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Medium.woff') format('woff');
  /* Add other font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManifoldCF-Regular';
  src: url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Regular.woff2') format('woff2'),
  url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Regular.woff') format('woff');
  /* Add other font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManifoldCF-DemiBold';
  src: url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-DemiBold.woff2') format('woff2'),
  url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-DemiBold.woff') format('woff');
  /* Add other font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManifoldCF-Light';
  src: url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Light.woff2') format('woff2'),
  url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Light.woff') format('woff');
  /* Add other font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManifoldCF-ExtraBold';
  src: url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-ExtraBold.woff2') format('woff2'),
  url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-ExtraBold.woff') format('woff');
  /* Add other font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManifoldCF-Bold';
  src: url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Bold.woff2') format('woff2'),
  url('https://vieutopia.com/fonts/Manifold CF v4.0/Webfont/ManifoldCF-Bold.woff') format('woff');
  /* Add other font formats if necessary */
  font-weight: normal;
  font-style: normal;
}


body {
  overflow-x: hidden !important;
  //transform: scale(1,2);
  //-ms-transform: scale(1,2); /* IE 9 */
  //-webkit-transform: scale(1,2); /* Safari and Chrome */
  //transform-origin: left top; /*设置左上角为缩放原点*/
}


body {
  margin: 0;
  font-family: 'ManifoldCF-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'ManifoldCF-Medium', sans-serif,
  monospace;
}

.global-font {
  font-family: 'ManifoldCF-Medium', sans-serif;
}

.ant-btn-primary {
  &:hover {
    background-color: #582859 !important;
    color: #dd4472 !important;
    border-color: #dd4472 !important;
  }
}

.ant-btn-default {
  &:hover {
    background-color: #582859 !important;
    color: #dd4472 !important;
    border-color: #dd4472 !important;
  }
}
