

@media only screen and (max-width: 600px){
  .m-special-cm{
    margin: 2rem 0 0 8vw;
  }
  .m-pad-cm{
    padding: 0.5rem 0 2rem 0!important;
  }
  .comment-txt{
    font-family: ManifoldCF-Medium, sans-serif!important;
  }
}
