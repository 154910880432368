.lg-password-div{
  display: flex;
  width: 25rem;
  height: 19.1875rem;
  padding: 1.27738rem 0.75481rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.91606rem;
  flex-shrink: 0;
  border-radius: 2rem;
  //border: 1.701px solid #FFF;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.55125px 2.55125px 1.70084px 0px rgba(255, 255, 255, 0.10) inset, -1.70084px 0px 1.70084px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}
.lg-password-ct-btn{
  width: 11.43781rem;
  height: 2rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  background: #8F3790;
  color: white;
  font-weight: 600;
  font-family: ManifoldCF-Medium,sans-serif;
}