.m-show {
  display: none;
}
@media only screen and (max-width: 600px){
  .m-nothing{
    display: none!important;
  }
  .m-show {
    display: initial;
    overflow-x: hidden!important;
  }
}