.about-text {
  color: white;
  font-family: ManifoldCF-Medium, sans-serif;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.125rem;
}

.about-col {
  border-radius: 2.25rem;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 2.5512535572052px 2.5512535572052px 1.7008357048034668px 0px rgba(255, 255, 255, 0.10) inset, -1.7008357048034668px 0px 1.7008357048034668px 0px rgba(255, 255, 255, 0.20) inset;
  backdrop-filter: blur(52.024810791015625px);
}

.divider {
  width: 100%;
  height: 0.0625rem;
  flex-shrink: 0;
  border-bottom: 1px solid var(--separators-separator, rgba(255, 255, 255, 0.07));
}

.about-nav-row{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}