//.center-image-container {
//  display: flex;
//  justify-content: center; /* Center horizontally */
//  align-items: center; /* Center vertically */
//  height: 70%!important; /* Set the height to full viewport height */
//}

//.center-image {
//  .t2a-gen-img{
//    text-align: center;
//    display: flex;
//    justify-content: center; /* Center horizontally */
//    align-items: center; /* Center vertically */
//    max-width: 30%; /* Ensure the image doesn't exceed its container */
//    max-height: 50%; /* Ensure the image doesn't exceed its container */
//  }
//}

.recent-slider{
  margin: 0 5% 0 5%;
  padding: 2rem 0 2rem 0;
  .slick-track{
    display: flex;
    align-items: center;
    column-gap: 3.6rem;
  }
  .slick-arrow{
    width: 1.62581rem;
    height: 3.62844rem;
    flex-shrink: 0;
  }
}