.header {

}

@media only screen and (max-width: 900px) {
  .header-dt-menu {
    display: none;
  }
}

@media only screen and (max-width: 600px){
  .m-header{
    display: flex;
    justify-content: space-between;
    background-color: black;
  }
  .header-right {
    display: flex;
    align-items: center;
    margin-left: 80vw; /* Pushes the MobileMenu to the right */
  }
}